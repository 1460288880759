.image {
  object-fit: contain;
  height: 10rem !important;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  padding-top: 1.9rem;
  padding-bottom: 0.1rem;
}

.caption {
  box-sizing: content-box;
}

.figure {
  width: 16.25rem;
}

.check_hole {
  top: 10px;
  right: 10px;
  width: 1rem;
  height: 1rem;
}

.check_circle {
  top: 10px;
  right: 10px;
  width: 1rem;
  height: 1rem;
}

.tablestyle {
    thead th {
        border: none;
        background: #EDEDEF;
        
    }
    thead th:nth-child(1) {
        border-top-left-radius: 4px;
        width: 10%;
    }
    thead th:nth-last-child(1) {
        border-top-right-radius: 4px;
    }
    tbody tr {
        border: 1px solid #EDEDEF;
    }
    tbody td {
        vertical-align: middle !important;
    }
}

.paginator {
    text-align: center;
    a {
        color: #000;
    }
    span {
        padding: 0 20px;
    }
}
.search_bar {
    margin-bottom: 0 !important;
    width: 40% !important;
    margin-right: 16px !important;
    input {
        border-color: #B9B9C0 !important;
        border-right: 0 !important;
    }
    
}
.search_icon {
    background: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
    border-color: #B9B9C0 !important;
    svg {
        color: #777785 !important;
    }
}
.invite_button {
    & ,
    &:hover,
    &:focus,
    &:active,
    &::selection{
        border-color: #644BF8 !important;
        background-color: #644BF8 !important;
    }
}
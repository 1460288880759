.container {
  background-color: white;
  color: black;
}

.subtext {
  font-family: arial, sans-serif;
  list-style: none;
  font-size: 14px;
  line-height: 1.58;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #609;
  top: 0;
  left: 0;

  .ad {
    font-family: arial, sans-serif;
    list-style: none;
    line-height: 1.58;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: #fff;
    color: #202124;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0px;
    padding: 0;
    margin-right: 0;
  }

  .link {
    font-family: arial, sans-serif;
    list-style: none;
    line-height: 1.58;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #202124;
    white-space: nowrap;
  }
  .arrow {
    font-family: arial, sans-serif;
    color: #222;
    list-style: none;
    font-size: 14px;
    line-height: 1.58;
    display: inline-block;
    margin: 0 3px;
    position: initial;
    width: 12px;
  }
}

.header {
  font-family: arial, sans-serif;
  list-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #1a0dab;
  display: inline-block;
  font-size: 20px;
  line-height: 26px;
  padding-top: 3px;
  margin-bottom: 3px;
  &:hover {
    text-decoration: underline;
  }
}

.body {
  font-family: arial, sans-serif;
  list-style: none;
  font-size: 14px;
  line-height: 1.58;
  padding-top: 0px;
  margin-bottom: 0px;
  color: #4d5156;
  white-space: pre-line;
}

.navpills {
    border: 1px solid #7155FF !important;
    border-radius: 5px !important;
    padding: 6px 40px !important;
    color: #7155FF;
    &_active {
        background-color: #421CFF !important;
        border: 1px solid #7155FF;
        border-radius: 5px !important;
        padding: 6px 40px !important;
        @media screen and (max-width: 500px) {
            padding: 6px 20px !important;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 500px) {
        padding: 6px 20px !important;
        margin-bottom: 10px;
    }
}
.left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0;
}
.right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0;
}

.tab_panel {
    padding: 40px 0;
}

.asterisk_icon {
    font-size: 12px;
    color: #5995ED;
}
.ad_container {
    padding: 20px;
    max-width: 500px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 30%);
}
.ad_name {
    font-weight: bold;
}
.ad_description {

}
.ad_media img{
    width: 100%;
}
.ad_media {
    padding: 10px;
    //margin-bottom: 20px;
}

.circle_badge {
  position: relative;
  line-height: 1;
}

.circle_icon {
  fill: #1cc88a;
}

@keyframes pulsate {
  0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0;
  }
}

.ringring {
  border: 3px solid #1cc88a;
  -webkit-border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -1px;
  right: -5px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

.card {
  :global {
    .badge-new {
      background-color: #b5d0f7;
    }

    .container {
      padding-left: 0;
      padding-right: 0;
      font-size: 18px;
    }
  }
}

.card_body {
  overflow: auto;
}

.ls_none {
  list-style: none;
  padding: 20px;
}

main {
  min-height: calc(100vh - 100px);
}

.header {
  overflow: auto;
}

.header img {
  display: block;
  height: 128px;
  margin-left: auto;
  margin-right: auto;
}

.header h4 {
  text-align: center;
}
$small_text: 12px;
$medium_text: 14px;
$large_text: 16px;
$extra_large_text: 18px;

.edit_button {
  margin-right: 8px;
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

.drop_button {
  margin-right: 8px;
  display: inline;
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
}

.submit_for_review_button {
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

.approve_button {
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

.cancel_button {
  margin-right: 8px;
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

.save_button {
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

.container {
  background-color: #f5f5f5;
  min-height: 500px;
  padding: 50px 20px 20px;
}

.card {
  width: 300px;
  margin: 0 auto 30px;
  padding: 8px;
  background-color: #fff;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  overflow: hidden;
}

.promoted {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0 0 8px;
  font-size: 16px;
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.logo {
  width: 48px;
  height: 48px;

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    object-position: 0 0;
  }
}

.text {
  padding: 0 10px;
}

.title {
  font-size: 14px;
  line-height: 1.2em;
  color: #000;
  font-weight: 400;
}

.description {
  font-size: 12px;
}

.note {
  font-size: 12px;
}
.container {
  .item {
    margin-bottom: 1rem;

    .title {
      font-weight: bold;
    }

    .value {
      color: blue;
    }

    .description {
      font-size: 11px;
    }
  }
}

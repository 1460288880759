.addition {
    border-left: 5px solid rgb(139, 202, 102);
}

.subtraction {
    border-left: 5px solid rgb(192, 93, 90);
}

.modification {
    border-left: 5px solid rgb(237, 193, 67);
}

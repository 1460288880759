.chip {
  padding: 0 10px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  border-radius: 2px;
  border: 1px solid #547054;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #cbe9cb;
  float: left;
}

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}

.reachDiv {
  width: 100%;
  font-size: 11px;
  text-align: center;
}

.reachDivLabel{
  font-weight: bold;
}

.media_square {
  object-fit: cover;
}

.text_square {
  overflow-wrap: break-word;
}

.container {
  width: 100px;
  height: 100px;
  position: relative;
}

.check_button {
  top: 8px;
  right: 8px;
  position: absolute;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
}

.modal_content {
  border-radius: 8px !important;
  .scrollable_content {
    overflow-x: auto;
  }
}

.modal_header {
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
}

.footer_text {
  color: black;
  font-size: 12px;
  font-weight: 400;
  margin-right: 15px;
}

.no_arrow {
    &::after {
        display: none !important;
    }
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.nav_settings {
    color: #fff;
    text-align: center;
}
.bd_left {
    border-left: 1px solid #333;
    padding: 0 10px;
}
.settings_text {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.settings_icon {
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
        animation: spin 2s linear infinite !important;
    }
    &:active {
        color: #ccc;
    }
}
.w200px {
    width: 200px;
}
.btnlft {
    text-align: left !important;
}
.mr_10{
    margin-right: -10px;
}
@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
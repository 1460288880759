.modal65w {
  width: 65%;
  max-width: 65% !important;
}

.selectionCard {
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 12px;
  padding-bottom: 0;

  &:hover {
    border: 1px solid rgb(0, 110, 255);
  }
}

.selectionCardLogo {
  max-width: 100px;
  max-height: 18px;
  margin: 0 auto;
  width: auto !important;
}

.selectionCardDiv {
  border: 2px dashed #ddd;
  height: 18px;
}
.products_selector_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1rem;
  overflow-y: scroll;
  max-height: calc(100vh - 21rem);
  min-height: 24rem;
}

.products_selector_list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - 21rem);
  min-height: 24rem;
}

.product_selector_label {
  font-size: 14px;
  font-weight: 500;
}

.product_selector_filter {
  font-size: 14px !important;
}

.results_info_section {
  margin-top: 24px;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 300;
}

.results_text {
  font-style: italic;
  margin-right: 12px;
  white-space: nowrap;
}

.select_all_margin_left {
  margin-left: 16px;
}

.spinnerContainer {
  display: flex;
  align-items: center;
}

.spinner {
  color: #7155FF;
  height: 18px !important;
  width: 18px !important;
  margin-left: 8px;
}

.tick {
  color: #4FAD80;
  margin-left: 8px;
}

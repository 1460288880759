.caption_text {
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  margin-top: 8px;
}

.inputs_background {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 24px;
}

.blue_horizontal_rule {
  border-color: #d0c6ff;
  margin-top: 8px;
}

.add_page_button {
  font-size: 14px !important;
  font-weight: 500;
  color: #3a3b4f;
  border-color: #3a3b4f;
}

.summary_values {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
}

.summary_value_flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_settings_summary {
  display: flex;
  align-items: center;
  gap: 8px;
}

.summary_bars_icon {
  color: #b9b9c0;
  margin: 8px;
  font-size: 12px;
}

.summary_page_icon {
  color: #5995ed;
  margin: 8px;
  font-size: 32px;
}

.summary_button {
  margin-left: 10px;
}

.page_name_summary_value {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.homepage_flag_summary_value {
  font-size: 12px;
  font-weight: 400;
  color: #207172;
}

.homepage_badge {
  background-color: #e7f9f9;
  border-radius: 4px;
  padding: 3px 8px;
  margin-left: 8px;
  gap: 10px;
}

.error_badge {
  background-color: #ef767a;
  border-radius: 4px;
  padding: 3px 8px;
  margin-left: 8px;
  gap: 10px;
}

.error_badge_text {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.url_date_summary_value {
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  font-style: italic;
  width: 250px;
  word-wrap: break-word;
}

.save_button_icon {
  color: #4fad80;
}

.edit_button_icon {
  color: #7155ff;
}

.remove_button_icon {
  color: #ef767a;
}

.hide_button_icon {
  color: #000000;
}

.view_button_icon {
  color: #000000;
}

.save_button {
  display: flex;
  gap: 2px;
  background: none;
  border: none;
  align-items: center;
}

.base_url {
  font-weight: 300;
  font-size: 16px;
  color: #000000;
  padding-top: 8px;
}

.page_url {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 8px;
}

.page_url_input {
  flex: 1;
  min-width: 200px;
}

$button-font-size: 13.33px;
$button-font-weight: 500;

.submit_for_review_button {
  min-width: 100px;
  font-size: $button-font-size !important;
  font-weight: $button-font-weight !important;
  max-height: 41px;
  &:not([disabled]) {
    color: #7155ff !important;
    border-color: #7155ff !important;

    &:hover {
      color: white !important;
      background-color: #7155ff;
    }
  }
}

.dropdown_label {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.option {
  height: 32px;
  width: 100%;
}

$bootstrap-form-error-red: #ef767a;

.dropdown_error_text {
  font-size: 12.8px;
  color: $bootstrap-form-error-red;
  padding-left: 12px;
  padding-top: 4px;
  align-self: flex-start;
}

.form_error_text {
  font-size: 12.8px;
  color: $bootstrap-form-error-red;
  padding-top: 4px;
  align-self: flex-start;
}

.form_error_text_products {
  font-size: 12.8px;
  color: $bootstrap-form-error-red;
  padding-top: 4px;
}

.search_bar {
    input {
        border-color: #B9B9C0 !important;
        border-left: 0 !important;
    }
    
}
.search_icon {
    background: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    border-color: #B9B9C0 !important;
    svg {
        color: #777785 !important;
    }
}

.card_border {
  border-color: #A18EFF;
}
.card_body {
    max-height: 30rem;
    overflow: auto;
}
.switch {
  display: flex;
  border: 1px solid #777785;
  border-radius: 4px;
  overflow: hidden;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  color: #777785;
  background-color: #ffffff;
  width: 40px;
  cursor: pointer;
}

.tab.selected {
  color: #ffffff;
  background-color: #7155ff;
}

.youtube_container {
  background: rgba(0, 0, 0, 0);
}

.display_text {
  color: #808080;
  font-size: 10px;
  text-align: center;
  width: 100%;
}

.info {
  font-size: 10px;
  font-family: Roboto, Arial, sans-serif;
  color: rgb(3, 3, 3);
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.info_contents {
  font-size: 10px;
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.primary_info {
  font-size: 10px;
  font-family: Roboto, Arial, sans-serif;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 8px 0;
}

.primary_info_title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  display: block;
  max-height: calc(2 * 2.4rem);
  overflow: hidden;
  font-weight: 400;
  line-height: 2.4rem;
  color: rgb(3, 3, 3);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  transform: none;
  text-shadow: none;
}

.container {
  font-size: 10px;
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.info_subtext {
  font-size: 10px;
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info_subtext_text {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  display: block;
  max-height: 2.1rem;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: normal;
  color: rgb(96, 96, 96);
}

.count,
.date {
  display: inline-block;
}

.dot {
  margin: 0 4px;
}

.separator {
  flex: 1;
  flex-basis: 1e-9px;
}

.menu {
  position: relative;
  top: 4px;
}

.menu_renderer {
  display: flex;
  flex-direction: row;
}

.icon {
  padding-right: 8px;
  width: 20px;
  height: 20px;
  fill: rgb(144, 144, 144);
  &.last {
    padding-right: 0;
  }
}

.label {
  transform: capitalize;
  font-weight: 500;
  color: rgb(144, 144, 144);
}

.button {
  padding-right: 8px;

  &.last {
    padding-right: 0;
  }
}

.info_menu {
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.like_bar {
  width: 103px;
  position: absolute;
  left: 0;
  display: inline-block;
  padding-top: 4px;
  padding-bottom: 28px;
}

.like_container {
  height: 2px;
  background-color: rgb(204, 204, 204);
}

.like_capacity {
  width: 97%;
  background-color: rgb(144, 144, 144);
  height: 2px;
  transition: width 0.3s;
}

.top_row {
  margin-bottom: 12px;
  padding-top: 16px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.profile_img {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: transparent;
  overflow: hidden;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: none;
    max-width: 100%;
    border-radius: none;
  }
}

.upload_info {
  -ms-flex: 1 1 1e-9px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 1e-9px;
  flex-basis: 1e-9px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.channel_name {
  z-index: 300;
  display: flex;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: normal;
}

.subscriber_count {
  color: rgb(96, 96, 96);
  margin-right: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: normal;
  text-transform: none;
}

.subscribe_container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.subscribe_button {
  background-color: rgb(204, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 3px;
  margin: 0 0.29em;
  width: auto;
  height: 38px;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  padding: 10px 16px;
  font-size: 14px;
  font-family: Roboto, Arial, sans-serif;
  line-height: normal;
}

.body_text {
  margin-left: 64px;
  white-space: pre-line;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.ad_background {
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-family: Roboto, Arial, sans-serif;
}

.ad_button {
  background-color: rgb(44, 90, 201);
  padding: 4px 6px;
  color: white;
  font-family: Roboto, Arial, sans-serif;
}

.ad_badge {
  background-color: rgb(242, 191, 65);
  color: white;
  padding: 0px 3px;
  margin-right: 5px;
  border-radius: 2px;
  font-family: Roboto, Arial, sans-serif;
}

.ad_logo {
  width: 40px;
}

.ad_logo_container {
  margin-right: 15px;
}

.ad_link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color:rgb(180, 180, 180);
  // display: inline-block;
}

.ad_link_container {
  width: 190px;
  display: flex;
}

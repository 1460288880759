.container {
  background-color: #f5f5f5;
  min-height: 500px;
  padding: 50px 20px 20px;
}

.note {
  max-width: 624px;
  margin: 0 auto 10px;
  font-size: 12px;
}

.card {
  max-width: 624px;
  margin: 0 auto 10px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 8px 16px 0;
  margin-bottom: 12px;
}

.logo {
  width: 48px;
  height: 48px;

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
    object-position: 0 0;
  }
}

.organisation {
  padding: 0 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  a {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
  }
}

.intro {
  padding: 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}

.media {
  margin-top: 4px;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #f5f7f9;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}

.headline {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}

.link {
  color: rgba(0, 0, 0, 0.6);
}

.cta {
  padding: 8px 16px;
  border: 1px solid #0a66c2;
  border-radius: 24px;
  color: #0a66c2;
  text-transform: capitalize;
}
:global {
  .xxl_modal .modal-dialog {
    @media (min-width: 1420px) {
      max-width: 1300px;
    }
    @media (min-width: 1620px) {
      max-width: 1400px;
    }
    @media (min-width: 1720px) {
      max-width: 1600px;
    }
    @media (min-width: 1900px) {
      max-width: 1700px;
    }
  }

  .modal-content {
    width: 100% !important;
  }
}

.product_selector_modal_header_area {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 12px 16px;
}

.product_selector_modal_header_text {
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
}

.header_right {
  display: flex;
  align-items: center;

  .close {
    order: 2;
  }
}

.modal_warning_text {
  color: #ef767a;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #ef767a;
  border-radius: 4px;
  padding: 12px 20px;
}

.products_selected_header {
  font-size: 23px;
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.container {
  min-height: 200px;
  border: 2px dashed #5a5c69;
}

.container_empty {
  justify-content: center;
  align-items: center;
}

.container_grid {
  justify-content: left;
  align-items: start;
}

.dragged {
  border: 2px dashed rgb(0, 95, 204);
  background-color: #f2fcff;
}

.container_text {
  color: #5a5c69;
  justify-content: center;
  align-items: center;
}

.container_group {
  justify-content: center;
  align-items: center;
  top: 50%;
  flex-wrap: wrap;
}

.container_button {
  background: transparent;
  font-weight: bold;
  padding: 5px 10px;
  color: #5a5c69;
  border-width: 2px !important;
  border-color: #5a5c69 !important;
}

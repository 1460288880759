.block_type_tiles_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  gap: 1rem;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}

.block_type_tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
  padding: 10px;
  border: transparent 1px solid;
  border-radius: 4px;
  width: 190px;
  height: 190px;
  &:hover {
    border-color: #007bff;
  }
}

.selected_tile {
  border-color: #007bff;
}

.preview_icon {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.block_name {
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.heading_text {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #3a3b4f;
}

.header_footer_block {
  background: #f8f8f8;
  min-height: 84px;
  align-items: center !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.add_button {
  min-width: 100px;
  font-size: 14px !important;
  font-weight: 500 !important;
  max-height: 41px;
  max-width: 58px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
  &:disabled {
    color: #b9b9c0 !important;
    border-color: #b9b9c0 !important;
    background: transparent !important;
  }
}

.modal_dialog {
  width: auto !important;
  max-width: 70% !important;
}
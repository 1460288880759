.slider_start_label {
    display: inline !important;
    margin-right: 13px;
    color: #6d767e;
}

.slider {
    display: inline !important;
    width: 80% !important;
    position: absolute !important;
    margin-top: 3px;
}

.slider_end_label {
    display: inline !important;
    margin-right: 13px !important;
    float: right;
    color: #6d767e;
}


.ad_item {
  width: 250px !important;
}

.ad_media_preview {
  // width: 100% !important;
  padding: 15px !important;
  background-color: #fff !important;
  max-width: 100% !important;
  object-fit: cover;
  // height: 150px !important;
}

.mw_85 {
  max-width: 85%;
  margin: auto;
}
.modalTop {    
    top: -30px !important;
}

.modalFooter {
    justify-content: left !important;
    border-top: 0 none !important;
}

.well {
    width: 100%;
    display: flex;
    align-items: center;
  }

.modalheader {
    border-bottom: 0 none !important;
}

$small_text: 12px;
$medium_text: 14px;
$large_text: 16px;
$extra_large_text: 18px;

.section {
  border-color: #f8f9fa;
  background: #fff;
  border: 1px solid #D4CCFF;
  margin-bottom: 60px;
}

.error_section {
  border: 1px solid #dc3545;
}

.ad_header {
  height: auto;
  padding: 3px 5px;
  padding-top: 20px;
  padding-left: 10px;
  position: relative;
}

.ad_header_title {
  /*margin: auto;*/
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 16px;
}

.ad_header_error {
  padding-left: 5px;
}

.linked_campaign {
  font-size: 11px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.panel-header {
  padding: 0;
  margin: 0;
}

.accordionHeader {
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #6c757d;
}

.accordionBody {
  padding: 5px;
  margin-bottom: 5px;
}

.collapsibleElements {
  margin-bottom: 25px;
}

.ad_custom_audience {
  margin-bottom: 20px;
  border: 1px solid transparent;
  padding: 0 5px;
}

.ad_selected_custom_audience {
  border: 1px solid rgb(0, 145, 0);
  border-radius: 10px;
  padding: 0 5px;
  margin-bottom: 20px;
}

.form_section {
  height: fit-content;
}

.subsection {
  border: 1px solid #f8f9fa;
  background-color: #fff;
  border-radius: 5px;
  margin: 0.75rem;
  display: flex;
  flex-direction: column;
}

.subheader_wrapper {
  background: #ededed;
  padding: 1rem;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  height: 64px;
  padding: 24px;

  .subheader {
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    margin: 0;
  }
}

.tab_container_navitem {
  text-align: center;
  background-color: #d8d8d8;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 2px;
  font-size: 12px;
  width: 70px;
  height: 53px;

  &:global(.active) {
    background-color: #fff !important;
    border-bottom: 0;
  }
}


.tab_container_navlink {
  color: #595959;
  font-weight: normal;
  padding: 0 !important;
  position: relative;
}

.tab_container_dots {
  margin: 1rem auto 0;
  text-align: center;
}

.tab_container_dot {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border: 1px solid #5995ED;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: auto 0.25rem;

  &:global(.active) {
    background-color: #5995ED !important;
  }
}

.tab_container_activetab_title {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  margin: 0.5rem auto 1rem;
}

.tab_container_activetab_container {
  display: flex;
  width: 100%;
}

.tab_container_activetab_description {
  display: inline-block;
  text-align: center;
  background-color: #d0c6ff;
  border-radius: 50px;
  font-size: 0.75rem;
  padding: 0.5rem;
  color: #3a3b4f;
  max-width: 600px;
  margin: 0 auto 1.5rem;
}

.circular_badge {
  position: absolute;
  right: 20px;
  top: 10px;
}

.tab_container_next_btn {
  &:global(.btn) {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem auto;
    text-align: center;
    padding: 0.25rem 2rem;
    background-color: transparent;
    border: 1px solid #7155ff;
    color: #7155ff;

    &:hover {
      background-color: #7155ff;
      color: #fff;
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: transparent !important;
    border: 1px solid #7155ff !important;
    color: #7155ff !important;
  }
}

.select_readonly {
  & > div {
    background-color: #eaecf4 !important;
    opacity: 1 !important;
    border: 1px solid #d1d3e2 !important;
    & > div > div {
      color: #495057 !important;
    }
  }
}

.goals {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 4px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 16px;
  &_muted {
    padding-left: 1.25rem;
  }
}

.budget {
  color: #000;
  font-size: 12px;
}


//Product Variables
.products_preview_area {
  margin-top: 12px;
  border-color: #d0c6ff;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  padding: 8px;
}

.attach_products_button {
  font-size: 14px !important;
  font-weight: 500;
  color: #3a3b4f;
  border-color: #3a3b4f;
}

.center_items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products_preview_empty {
  font-size: $small_text;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
  margin-top: 8px;
}

.products_preview_empty_error {
  font-size: $small_text;
  font-weight: 400;
  color: #dc3545;
  margin-bottom: 8px;
  margin-top: 8px;
}

.product_preview_item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.product_image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.product_name {
  font-size: $small_text;
  font-weight: 500;
  color: #000000;
}

.product_preview_hr {
  border-color: #d0c6ff;
  margin-top: 8px;
  margin-bottom: 8px;
}

.scrollable_products_preview_list {
  overflow-y: auto;
  max-height: calc(100vh - 21rem);
}

.min_products_area_height {
  min-height: 24rem;
}

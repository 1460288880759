.vertical-align-initial{
    vertical-align: initial;
}

.modal65w {
    width: 65%;
    max-width: 65% !important;
}

.modalflex{
    width: auto;
    max-width: 1130px !important;
    @media screen and (max-width: 1200px) {
        max-width: 80% !important;
    }
    @media screen and (max-width: 575px) {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.withMargin {
    margin-bottom: 7px;
}

.modalHeader {
    border-bottom: 2px solid rgb(66, 184, 252);
}

.modalHeader h4 {
    margin-bottom: 15px;
}

.modalAdSearch {
    float: right;
    margin-bottom: 10px;
}

.ad-preview {
    margin: 5px;
}

.modalFooter {
    height: 150px;
    border: 0 !important;
}

.centerVert {

    margin-top: auto;
    margin-bottom: auto;
}

.modalAdBoxLabel {
    margin: 0;
    padding: 0;
}

.modalAdBox {
    padding: 5px;
    border: 1px solid #e8e8e8;
}

.modal_header {
    border-bottom: 0 !important;
    background: #F8F8F8;
}
.modal_title {
  font-size: 18px;
  color: #3A3B4F;
}

.modal_fixes {
  padding: 0 !important;
  padding-bottom: 10px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #F8F8F8;
}

.adDetails {
    height: 120px;
}

.selectedType {
    padding: 5px;
    border: 1px solid transparent;
    outline: 2px solid rgb(66, 184, 252);
}

// Modal elements borders
$modal-border-rad: 4px;

.platform {
    
    &_box {
        background-color: #B9B9C0;
        border: 1px solid #B9B9C0;
        border-radius: $modal-border-rad;
        margin-bottom: 20px;
        transition: 0.3s;
    }
    &_icon {
        text-align: center;
        color: #fff;
        padding: 20px;
    }
    &_iconsize {
        height: 40px;
        width: 40px;
    }
    &_text {
        color: #000;
        background-color: #fff;
        text-align: center;
        border-bottom-left-radius: $modal-border-rad;
        border-bottom-right-radius: $modal-border-rad;
        transition: 0.3s;
        text-transform: capitalize;
    }
}
.platform_box:hover,
.selected {
    background-color: #A18EFF;
}

.ad_objective {
    border-radius: $modal-border-rad;
    background: #F8F8F8;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        border: 1px solid #006eff;
    }
    hr {
        border-top: 1px solid #B9B9C0;
        width: 100%;
     }
    &_title {
        font-weight: bold;
        padding-top: 5px;
        + p {
            font-size: 15px;
        }
    }
    button {
        background-color: #fff;
        border: 1px solid #7155FF;
        color: #7155FF;
    }
    button:hover,
    button::selection {
        background-color: #7155FF !important;
        border: 1px solid #7155FF !important;
        color: #fff !important;
    }
    &_info {
        display: flex;
        align-items: center;
    }
    &_info_text {
        strong {
            font-size: 14px;
        }
        p {
            margin-bottom: 0;
            font-size: 12px;
        }
    }
    &_image_section {
        width: 100%;
        height: 192px;
        text-align: center;
        background: #fff;
        padding: 10px;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    svg {
        margin: 0 10px;
        color: #B9B9C0
    }
    &_row {
        margin-bottom: 20px;
    }
    
}
.recommended {
    background-color: #FFF5CC;
    hr {
        border-top: 1px solid #CCA300 !important;
        width: 100%;
    }
    svg {
        color: #CCA300;
    }
    .ad_objective_image_section::after{
        content: 'Recommended';
        color:#000;
        position: absolute;
        top: 25px;
        left: 42px;
        background: #FFE787;
        padding: 2px 8px;
        border-radius: 5px;
        font-size: 12px;
    }
}

.tab_container_navitem {
  text-align: center;
  background-color: #dedede;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 2px;
  font-size: 12px;
  width: 70px;
  height: 53px;

  &:global(.active) {
    background-color: #fff !important;
    border-bottom: 0;
  }
}


.tab_container_navlink {
  color: #595959;
  font-weight: normal;
  padding: 0 !important;
  position: relative;
}

.tab_container_dots {
  margin: 1rem auto 0;
  text-align: center;
}

.tab_container_dot {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border: 1px solid #5995ED;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: auto 0.25rem;

  &:global(.active) {
    background-color: #5995ED !important;
  }
}

.tab_container_activetab_title {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  margin: 0.5rem auto 1rem;
}

.tab_container_activetab_container {
  display: flex;
  width: 100%;
}

.tab_container_activetab_description {
  display: inline-block;
  text-align: center;
  background-color: #d0c6ff;
  border-radius: 50px;
  font-size: 0.75rem;
  padding: 0.5rem;
  color: #3a3b4f;
  max-width: 600px;
  margin: 0 auto 1.5rem;
}

.circular_badge {
  position: absolute;
  right: 20px;
  top: 10px;
}

.tab_container_next_btn {
  &:global(.btn) {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem auto;
    text-align: center;
    padding: 0.25rem 2rem;
    background-color: transparent;
    border: 1px solid #7155ff;
    color: #7155ff;

    &:hover {
      background-color: #7155ff;
      color: #fff;
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: transparent !important;
    border: 1px solid #7155ff !important;
    color: #7155ff !important;
  }
}

.figure {
  height: 100%;
}

.img {
  object-fit: cover;
  background-color: #fff;
  height: 10rem;
  width: 100%;
}

.img_preview {
  padding: 10px;
  object-fit: cover;
  background-color: #fff;
  height: 10rem;
  width: 100%;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;

  width: 14rem;
}

.link {
  color: inherit;
  height: 100%;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.date {
  // color: #9d9d9d;
  color: black;
  font-size: 0.67rem;
}

.status_badge {
  font-size: 0.67rem !important;
}

.ad_icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 5px;
  background-color: white;
}

.platform_icons {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 0.7rem;
}

.platform_icon_filter {
  filter: drop-shadow(0 0 2px white);
  margin-left: 0.6rem;
}

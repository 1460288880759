.media_square {
  object-fit: cover;
}

.text_square {
  overflow-wrap: break-word;
}

.container {
  border: 2px dashed #5a5c69;
  width: 100px;
  height: 100px;
  position: relative;

  &:hover .delete_button {
    display: block;
  }

  @media screen and (max-width: 768px) {
    .delete_button {
      display: block;
    }
  }
}

.delete_button {
  top: 8px;
  right: 8px;
  position: absolute;
  display: none;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
}

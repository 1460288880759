$small_text: 12px;
$medium_text: 14px;
$large_text: 16px;
$extra_large_text: 18px;

// Generic Content Block
.generic_content_block_header {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 20px;
}
.generic_content_block_body {
  background-color: #fafafa;
  border-radius: 8px;
  margin-top: -20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.generic_content_block {
  background-color: #fafafa;
  border-radius: 8px;
  margin-bottom: 16px;
}

// Block Header
.block_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.left_side {
  display: flex;
  gap: 10px;
  align-items: center;
}

.right_side {
  display: flex;
  gap: 8px;
}

.block_name_header {
  font-size: $extra_large_text;
  font-weight: 500;
  color: #000000;
}

.summary_bars_icon {
  color: #b9b9c0;
  font-size: 12px;
}

//Block Body
.variable_section {
  margin-top: 24px;
}

.variable_label {
  font-size: $medium_text;
  font-weight: 500;
  color: #000000;
}

// Add Block
.add_block_background {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add_block_placeholder_illustration {
  max-width: 199px;
}

.add_block_placeholder_text {
  font-size: $extra_large_text;
  font-weight: 500;
  color: #ef767a;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 22.5px;
}

.add_button {
  min-width: 100px;
  font-size: $medium_text !important;
  font-weight: 500 !important;
  max-height: 41px;
  color: #7155ff !important;
  border-color: #7155ff !important;
  background-color: transparent !important;
  &:hover {
    color: white !important;
    background-color: #7155ff !important;
  }
}

// Link Variables
.link_radio_options {
  display: flex;
}

.link_radio_option {
  margin-right: 16px;
  color: black;
  font-size: $large_text;
  font-weight: 300;
}

.link_input_container {
  margin-top: 16px;
}

.link_info_text {
  font-size: $small_text;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}

.product_info_text {
  font-size: $small_text;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}

//Image Variables
.creative_guidelines {
  font-size: $small_text;
  font-weight: 400;
  color: #00001e;
}

//Product Variables
.products_preview_area {
  margin-top: 12px;
  border-color: #d0c6ff;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  padding: 8px;
}

.attach_products_button {
  font-size: 14px !important;
  font-weight: 500;
  color: #3a3b4f;
  border-color: #3a3b4f;
}

.center_items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products_preview_empty {
  font-size: $small_text;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
  margin-top: 8px;
}

.products_preview_empty_error {
  font-size: $small_text;
  font-weight: 400;
  color: #dc3545;
  margin-bottom: 8px;
  margin-top: 8px;
}

.product_preview_item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.product_image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.product_name {
  font-size: $small_text;
  font-weight: 500;
  color: #000000;
}

.product_preview_hr {
  border-color: #d0c6ff;
  margin-top: 8px;
  margin-bottom: 8px;
}

.scrollable_products_preview_list {
  overflow-y: auto;
  max-height: calc(100vh - 21rem);
}

.min_products_area_height {
  min-height: 24rem;
}

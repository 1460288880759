.grid {
    display: flex;
    flex-wrap: wrap;

    // Replace when safari supports flex gap.
    // gap: 1rem;
    & > * {
        margin-right: 1rem;
    }
}

.tablestyle {
    tbody tr:nth-of-type(odd) {
        background-color: #F7F5FF;
        td:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        td:nth-last-child(1) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    tbody td {
        vertical-align: middle;
        border: 0;
        &:nth-last-child(1) div {
            justify-content: end;
        }
        &:nth-last-child(1) {
            width: 40%;
        }
    }
}
.ml_5px {
    margin-left: 5px;
    &,
    &:hover{
        color: rgb(33, 37, 41);
    }
}
.tablestyle {
  border: 1px solid #A18EFF;
  border-radius: 5px;
  margin-bottom: 10px;
  table {
      margin-bottom: 0;
  }
  td {
      border-top: 0 !important;
      vertical-align: middle !important;
  }

} 
.card_border {
  border-color: #A18EFF;
}
.card_padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.product_list_tile {
  display: grid;
  grid-template-columns: 30px 30px 1fr auto auto;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  &:hover {
    border-color: #87f5c0;
  }
}

.even {
  background-color: white;
}

.odd {
  background-color: #f2effc80;
}

.checked {
  background-color: #E3FDF0;
}

.checkbox_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
}

.hidden {
  visibility: hidden;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  overflow: hidden;
}

.product_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

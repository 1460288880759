.modal {
  :global {
    .modal-header {
      background-color: #DADADA;
      .modal-title .text-secondary {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #000 !important;
      }
      .close span {
        color: #000 !important;
      }
    }
    .modal-body {
      background-color: #F4F4F4;
      padding: 20px 0;
      .form-label {
        font-weight: bold;
      }
    }

    .modal-footer {
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        width: 160px;
        background: #421CFF;
        border-radius: 4px;
      }
    }
  }
}
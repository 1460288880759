.filtered_products {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin-bottom: 0.6rem;
    margin-right: 0.6rem;
    overflow-y: scroll;
    max-height: calc(100vh - 21rem);
    min-height: 16rem;
}

.textarea {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    white-space: pre;
    word-wrap: normal;
    padding: 0.1em 0.3em;
    border: solid thin;
    overflow: auto;
}

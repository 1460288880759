.topHeading {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  text-align: left;
}

.subHeading {
  font-weight: normal;
  font-size: 23px;
  line-height: 32px;
}

.subHeadingSeparator {
  width: 100%;
  height: 1px;
  background: #C4C4C4;
  border: none;
}

.settingsBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #7155FF;
  border-color: #7155FF;
  color: #fff;
  width: 160px;
  padding: 8px 16px 8px;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: none;
}

.channelItemDiv {
  margin-left: 20px;
}

.channelItemTitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: #000000;
}

.channelItemDesc {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}
.sidebar_nav_item {
  &:hover {
    cursor: pointer;
  }
}

.sidebar_nav_label {
  font-size: 11.5px;
  font-weight: 400;
  display: block;

  &:hover {
    cursor: pointer;
  }
}

.sidebar_nav {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
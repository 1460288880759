// Variables
$purpleLight: #7155FF;
$purpleDark: #421CFF;

.webspacesTemplatesHeader {
  background-color: #000;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.react-bootstrap-table-pagination {
  float: right;
}

.webSpaceNoDataForFilter {
  text-align: "center";
  height: "25px";
}

.toggleButton {
  min-width: 120px;
  background-color: transparent !important;
  color: #B9B9C0 !important;
  border-color: #B9B9C0 !important;

  &:global(.active) {
    background-color: $purpleDark !important;
    color: #fff  !important;
    font-weight: bold !important; 
  }
}

.groupingToggleButton {
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-right: 50px;
  background-color: transparent !important;
  color: #000000 !important;
  border: none !important;
  font-weight: bold !important; 

  &:global(.active) {
    color: rgb(0, 0, 0)  !important;
    text-decoration: underline 5px;
    font-weight: bold !important; 
  }
}

.underlineFloating {
  border-bottom: 5px solid #000;
}

.spaceLink,
.templateLink {
  text-decoration: underline;
}

.statusBadge {
  color: #000 !important;
  padding: 5px 8px !important;
  font-weight: normal !important;
}

.statusBadgeActive {
  background-color: #87F5C0 !important;
}

.statusBadgeInactive {
  background-color: #EF767A !important;
}

.markedBaseRateText {
  color: #5FA9AA;
}

.topHeading {
  color: #000;
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  text-align: left;
}

.tablePageButton {
  color: #000;
  background-color: transparent;

  & .active {
    color: red !important;
    background-color: transparent;
  }
}

.styled_table_header {
  th {
    background: #3A3B4F;
    color: #fff;
  }
}

.search_bar {
    margin-bottom: 0 !important;
    width: 40% !important;
    margin-right: 16px !important;
    input {
        border-color: #B9B9C0 !important;
        border-right: 0 !important;
    }
    
}
.search_icon {
    background: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
    border-color: #B9B9C0 !important;
    svg {
        color: #777785 !important;
    }
}
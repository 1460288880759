.tileContainer {
  margin: 0 auto;
  flex: 1;
  min-width: 200px;
  max-width: 250px;
  padding: 16px 8px;
  background: white;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  cursor: pointer;
  user-select: none;
  &.selected {
    border-color: #7155ff;
  }
  &:hover {
    border-color: #7155ff;
  }
}

.illustrationContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    margin: auto;
    max-width: 85%;
    max-height: 100%;
    pointer-events: none;
    user-drag: none;
  }
}

.optionFooter {
  display: flex;
  align-items: center;
  gap: 4px;
}

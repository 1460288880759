.group_style {
    display: flex;
    & > label,
    & > input {
        width: 50%;
    }
}
.form_check {
    width: 25%;
}
.date_small {
    .input-group {
        width: 50%;
    }
}

.color_style {
    display: inline-block;
    input {
        padding: 0 3px;
        margin: 0;
        width: 50px;
        height: 50px;
        border: 0;
        animation: grow 0.3s 1;
    }
}

@keyframes grow {
    from { scale: 0;}
    to {scale: 1;}
}

.addition {
    display: inline-block;
    margin-bottom: 1rem;
    padding: 7px 2px;
    vertical-align: bottom;
}

.list_items {
    width: 100%;
    input {
        display: inline-block;
        width: 90%;
        animation: grow-w 0.3s 1;
    }
    button {
        animation: grow 0.3s 1;
    }
}
@keyframes grow-w {
    from { scale: 0 1;}
    to {scale: 1 1;}
}
.product {
  display: grid;
  grid-template-rows: 1fr 4em;
  border: 2px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 4px #00000040;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  &.selected {
    border-color: #4fad80;
  }
  &:hover {
    border-color: #87f5c0;
  }
}

.product_image_wrapper {
  grid-row: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.product_image {
  width: 100%;
  object-fit: cover;
}

.product_body {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 12px;
  background-color: #f8f9fa;
  overflow: auto;
  min-height: 4em;
}

.product_name_text_wrapper {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price_text_wrapper {
  font-size: 10px;
  font-weight: 700;
  color: #000000;
}

.selected_check_box {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.selected {
  background-color: #86f4bf;
}

.unselected {
  background-color: #ffffff;
  border: 2px solid #b9b9c0;
}
